import {
  ApplicationConfig,
  LOCALE_ID,
  importProvidersFrom,
} from "@angular/core";

import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDividerModule } from "@angular/material/divider";
import { MatListModule } from "@angular/material/list";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CoreModule } from "./core/core.module";
import { OAuthModule, OAuthStorage } from "angular-oauth2-oidc";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NgIconsModule } from "@ng-icons/core";
import { NzIconModule } from "ng-zorro-antd/icon";
import {
  heroArrowPath,
  heroChatBubbleLeftRight,
  heroMagnifyingGlass,
  heroPlus,
  heroLockClosed,
  heroGlobeAmericas,
  heroMapPin,
  heroChevronDown,
  heroBackward,
  heroArchiveBoxArrowDown,
  heroArrowLeftOnRectangle,
  heroPhoto,
} from "@ng-icons/heroicons/outline";
import {
  heroGlobeAmericasSolid,
  heroLockClosedSolid,
} from "@ng-icons/heroicons/solid";
import { tablerCar, tablerBrandYoutube } from "@ng-icons/tabler-icons";
import { matAlarmOn, matAlarmOff } from "@ng-icons/material-icons/baseline";
import { WebsocketService } from "./core/websocket.service";
import { NgxImageCompressService } from "ngx-image-compress";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { HttpInterceptorService } from "./http/http-interceptor.service";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { NZ_DATE_LOCALE, NZ_I18N, pt_BR } from "ng-zorro-antd/i18n";
import { provideAnimations } from "@angular/platform-browser/animations";
import {
  AimOutline,
  AlertOutline,
  ApartmentOutline,
  ApiOutline,
  AppstoreOutline,
  ArrowDownOutline,
  ArrowLeftOutline,
  ArrowRightOutline,
  AuditOutline,
  BackwardOutline,
  BankOutline,
  BellOutline,
  BugOutline,
  CarOutline,
  CaretRightOutline,
  CheckOutline,
  CheckCircleOutline,
  ClearOutline,
  CreditCardOutline,
  CrownOutline,
  CopyOutline,
  DesktopOutline,
  DownloadOutline,
  DownOutline,
  EnvironmentOutline,
  ExclamationOutline,
  ExclamationCircleTwoTone,
  ExpandOutline,
  EyeOutline,
  EyeInvisibleOutline,
  FileSearchOutline,
  FilterOutline,
  FireOutline,
  FireFill,
  FlagOutline,
  FolderOpenOutline,
  FullscreenOutline,
  FullscreenExitOutline,
  GlobalOutline,
  HistoryOutline,
  InteractionOutline,
  LinkOutline,
  LockFill,
  LockOutline,
  MehOutline,
  MailOutline,
  MenuOutline,
  MenuFoldOutline,
  MenuUnfoldOutline,
  MessageOutline,
  MinusCircleOutline,
  MinusSquareOutline,
  MoreOutline,
  NodeIndexOutline,
  PaperClipOutline,
  PauseOutline,
  PicLeftOutline,
  PictureOutline,
  PlusOutline,
  PlusSquareOutline,
  PoweroffOutline,
  PushpinOutline,
  QuestionCircleOutline,
  ReloadOutline,
  RestOutline,
  RollbackOutline,
  SafetyCertificateFill,
  SafetyCertificateOutline,
  SafetyCertificateTwoTone,
  SafetyOutline,
  SaveOutline,
  SearchOutline,
  SendOutline,
  SolutionOutline,
  SoundOutline,
  StopOutline,
  TableOutline,
  TeamOutline,
  UnorderedListOutline,
  UserOutline,
  VideoCameraOutline,
  WarningFill,
  WarningOutline,
  FilePdfOutline,
} from "@ant-design/icons-angular/icons";

import { provideRouter, withComponentInputBinding } from "@angular/router";
import { routes } from "./app.routes";
import { JwtModule } from "@auth0/angular-jwt";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth/auth.service";
import { TokenStore } from "./auth/token.store";
import { NotificacoesStore } from "./admin-v2/data-access/notificacoes.store";
import { registerLocaleData } from "@angular/common";
import pt from "@angular/common/locales/pt";
import { pt as ptBR } from "date-fns/locale";
import {
  phosphorHighDefinitionBold,
  phosphorPersonSimpleWalkBold,
} from "@ng-icons/phosphor-icons/bold";

import { phosphorRobot } from "@ng-icons/phosphor-icons/regular";

import {
  provideTanStackQuery,
  QueryClient,
} from "@tanstack/angular-query-experimental";

registerLocaleData(pt);

export function tokenGetter() {
  return localStorage.getItem("token");
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    provideTanStackQuery(new QueryClient()),
    // Importa os providers dos módulos
    importProvidersFrom(
      BrowserModule,
      FormsModule,
      ReactiveFormsModule,
      MatBadgeModule,
      MatButtonModule,
      MatCardModule,
      MatDividerModule,
      MatListModule,
      MatInputModule,
      MatIconModule,
      MatMenuModule,
      MatProgressSpinnerModule,
      MatSidenavModule,
      MatTooltipModule,

      CoreModule,

      OAuthModule.forRoot(),
      NzButtonModule,
      NzDividerModule,
      NzDropDownModule,
      NzIconModule.forChild([
        AimOutline,
        AlertOutline,
        ApartmentOutline,
        ApiOutline,
        AppstoreOutline,
        ArrowDownOutline,
        ArrowLeftOutline,
        ArrowRightOutline,
        AuditOutline,
        BackwardOutline,
        BankOutline,
        BellOutline,
        BugOutline,
        CaretRightOutline,
        CarOutline,
        CheckOutline,
        CheckCircleOutline,
        ClearOutline,
        CopyOutline,
        CreditCardOutline,
        CrownOutline,
        DesktopOutline,
        DownloadOutline,
        DownOutline,
        EnvironmentOutline,
        ExclamationOutline,
        ExclamationCircleTwoTone,
        ExpandOutline,
        EyeOutline,
        EyeInvisibleOutline,
        FilePdfOutline,
        FileSearchOutline,
        FilterOutline,
        FireOutline,
        FireFill,
        FlagOutline,
        FolderOpenOutline,
        FullscreenOutline,
        FullscreenExitOutline,
        GlobalOutline,
        HistoryOutline,
        InteractionOutline,
        LinkOutline,
        LockFill,
        LockOutline,
        MailOutline,
        MehOutline,
        MenuOutline,
        MenuFoldOutline,
        MenuUnfoldOutline,
        MessageOutline,
        MinusCircleOutline,
        MinusSquareOutline,
        MoreOutline,
        NodeIndexOutline,
        PaperClipOutline,
        PauseOutline,
        PicLeftOutline,
        PictureOutline,
        PlusOutline,
        PlusSquareOutline,
        PoweroffOutline,
        PushpinOutline,
        QuestionCircleOutline,
        ReloadOutline,
        RestOutline,
        RollbackOutline,
        SafetyCertificateFill,
        SafetyCertificateOutline,
        SafetyCertificateTwoTone,
        SafetyOutline,
        SaveOutline,
        SearchOutline,
        SendOutline,
        SolutionOutline,
        SoundOutline,
        StopOutline,
        TableOutline,
        TeamOutline,
        UnorderedListOutline,
        UserOutline,
        VideoCameraOutline,
        WarningFill,
        WarningOutline,
      ]),
      NgIconsModule.withIcons({
        matAlarmOn,
        matAlarmOff,
        heroArrowPath,
        heroBackward,
        heroMagnifyingGlass,
        heroPhoto,
        heroPlus,
        heroChatBubbleLeftRight,
        heroLockClosed,
        heroLockClosedSolid,
        heroGlobeAmericas,
        heroGlobeAmericasSolid,
        heroMapPin,
        heroChevronDown,
        heroArchiveBoxArrowDown,
        heroArrowLeftOnRectangle,
        tablerCar,
        tablerBrandYoutube,
        phosphorHighDefinitionBold,
        phosphorPersonSimpleWalkBold,
        phosphorRobot,
      }),

      JwtModule.forRoot({
        config: {
          tokenGetter: tokenGetter,
          allowedDomains: environment.tokenWhitelistedDomains,
          disallowedRoutes: environment.tokenBlacklistedRoutes,
        },
      }),
    ),

    // Providers específicos do projeto
    WebsocketService,
    HttpClient,
    NgxImageCompressService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    { provide: TokenStore, useClass: TokenStore },
    { provide: NotificacoesStore, useClass: NotificacoesStore },
    { provide: OAuthStorage, useValue: localStorage },
    { provide: AuthService, useClass: AuthService },

    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: "never",
        appearance: "outline",
        subscriptSizing: "dynamic",
      },
    },
    { provide: NZ_I18N, useValue: pt_BR },
    { provide: NZ_DATE_LOCALE, useValue: ptBR },
    { provide: LOCALE_ID, useValue: "pt-BR" },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
};
