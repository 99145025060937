import { Routes } from "@angular/router";
import { LoginComponent } from "./public/login/login.component";

export const routes: Routes = [
  {
    path: "login",
    component: LoginComponent,
    data: { nomeModulo: "Página Inicial", public: true },
  },
  {
    path: "govbr",
    component: LoginComponent,
    data: { nomeModulo: "Página Inicial", public: true },
  },
  {
    path: "admin",
    loadChildren: () =>
      import("./admin/admin.routes").then((r) => r.ADMIN_ROUTES),
  },
  {
    path: "admin-v2",
    loadChildren: () =>
      import("./admin-v2/admin.routes").then((r) => r.ADMIN_ROUTES),
  },
  {
    path: "acessos",
    data: { public: true },
    loadChildren: () =>
      import("./acessos/acessos.routes").then((r) => r.ACESSOS_ROUTES),
  },
  {
    path: "alertas",
    loadChildren: () =>
      import("./alertas/alertas.routes").then((r) => r.ALERTAS_ROUTES),
  },
  {
    path: "auditoria",
    loadChildren: () =>
      import("./auditoria/auditoria.routes").then((r) => r.AUDITORIA_ROUTES),
  },
  {
    path: "consultas",
    loadChildren: () =>
      import("./consultas/consultas.routes").then((r) => r.CONSULTAS_ROUTES),
  },
  {
    path: "gerenciar-monitoramentos",
    loadChildren: () =>
      import("./gerenciar-monitoramentos/gerenciar-monitoramentos.routes").then(
        (r) => r.GERENCIAR_MONITORAMENTOS_ROUTES,
      ),
  },
  {
    path: "integracoes",
    loadChildren: () =>
      import("./integracoes/integracoes.routes").then(
        (r) => r.INTEGRACOES_ROUTES,
      ),
  },
  {
    path: "integracoes-new",
    loadChildren: () =>
      import("./integracoes-new/integracoes-new.routes").then(
        (r) => r.INTEGRACOES_NEW_ROUTES,
      ),
  },
  {
    path: "monitor",
    loadChildren: () =>
      import("./monitor/monitor.routes").then((r) => r.MONITOR_ROUTES),
  },
  {
    path: "p",
    loadChildren: () =>
      import("./public/public.routes").then((r) => r.PUBLIC_ROUTES),
  },
  {
    path: "pare",
    loadChildren: () => import("./pare/pare.routes").then((r) => r.PARE_ROUTES),
  },
  {
    path: "passagens-historico",
    loadChildren: () =>
      import("./passagens-historico/passagens.routes").then(
        (r) => r.PASSAGENS_ROUTES,
      ),
  },
  {
    path: "passagens-imagens",
    loadChildren: () =>
      import("./passagens-imagens/passagens-imagens.routes").then(
        (r) => r.PASSAGENS_IMAGENS_ROUTES,
      ),
  },
  {
    path: "passagens-origem-destino",
    loadChildren: () =>
      import("./passagens-origem-destino/passagens-od.routes").then(
        (r) => r.PASSAGENS_ORIGEM_DESTINO_ROUTES,
      ),
  },
  {
    path: "registros-operacionais",
    loadChildren: () =>
      import("./registros-operacionais/registros-operacionais.routes").then(
        (r) => r.REGISTROS_OPERACIONAIS_ROUTES,
      ),
  },
  {
    path: "solicitar-acesso",
    loadChildren: () =>
      import("./solicitacao-cadastro/solicitacao-cadastro.routes").then(
        (r) => r.SOLICITACAO_CADASTRO_ROUTES,
      ),
  },
  {
    path: "termo-nao-assinado",
    loadChildren: () =>
      import("./termo-nao-assinado/termo-nao-assinado.routes").then(
        (r) => r.TERMO_NAO_ASSINADO_ROUTES,
      ),
  },
  {
    path: "consulta-facial",
    loadChildren: () =>
      import("./consulta-facial/consulta.facial.routes").then(
        (r) => r.CONSULTA_FACIAL_ROUTES,
      ),
  },
  {
    path: "usuarios",
    loadChildren: () =>
      import("./usuarios/usuarios.routes").then((r) => r.USUARIOS_ROUTES),
  },

  {
    path: "passagens-veiculos-apoio",
    loadChildren: () =>
      import("./passagens-veiculos-apoio/passagens-veiculos-apoio.routes").then(
        (r) => r.PASSAGENS_VEICULOS_APOIO_ROUTES,
      ),
  },
  {
    path: "",
    redirectTo: "consultas",
    pathMatch: "full",
  },
  { path: "**", redirectTo: "/" },
];
